import React from 'react';

import { Avatar } from '../components/Avatar/Avatar';
import BigBanner from '../components/Banners/BigBanner';
import { Layout, Pagination } from '../components/common';
import { MetaData } from '../components/common/meta';

import style from '../styles/pages/authors.scss';

import cn from 'classnames';
import { graphql } from 'gatsby';

interface IAuthorProps {
  data: {
    allGhostAuthor: any;
  };
  location: {
    pathname: string;
  };
  pageContext: any;
}

/**
 * Authors page (/authors)
 *
 *
 */
const Authors = ({ data, location, pageContext }: IAuthorProps) => {
  const authors = data.allGhostAuthor.edges;

  return (
    <>
      <MetaData data={data} location={location} title="Authors" description="Description" />
      <Layout headerClassName={style.header} mainClass={style.main}>
        <div className={style.authorsPage}>
          <div className="container">
            <h1>Authors</h1>
            <div className="row">
              {authors.map((author: any, i: number) => (
                <div key={i} className={cn('col-12 col-sm-6 col-md-4 col-lg-3', style.authorCard)}>
                  <Avatar profile_image={author.node.profile_image} name={author.node.name} />
                  <h2 className={style.postName}>
                    <a href={`/author/${author.node.slug}`}>{author.node.name.split('@')[0]}</a>
                  </h2>
                  {author.node.bio ? (
                    <p
                      className={style.postBio}
                      dangerouslySetInnerHTML={{ __html: author.node.bio }}
                    />
                  ) : (
                    ''
                  )}
                  <span className={style.postsCount}>Posts: {author.node.postCount}</span>
                </div>
              ))}
            </div>
            <Pagination className={style.pagination} pageContext={pageContext} />
          </div>
        </div>
        <BigBanner />
      </Layout>
    </>
  );
};

export default Authors;

export const pageQuery = graphql`
  query($limit: Int!, $skip: Int!) {
    allGhostAuthor(sort: { order: DESC, fields: [postCount] }, limit: $limit, skip: $skip) {
      totalCount
      edges {
        node {
          ...GhostAuthorFields
          postCount
        }
      }
    }
  }
`;
