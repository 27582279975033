import React from 'react';

import style from './Avatar.scss';

import cn from 'classnames';

interface IAvatarProps {
  className?: string;
  profile_image: string;
  name: string;
}

export const Avatar = (props: IAvatarProps) => {
  const { className, profile_image, name = '' } = props;

  return (
    <div className={cn(className, style.profileImage)}>
      <div
        className={style.image}
        style={{
          backgroundImage: `url(${profile_image}?s=220&d=blank)`,
        }}
      />
      <h2>{name.slice(0, 1)}</h2>
    </div>
  );
};
